import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Box } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Countdown from 'react-countdown';
import { navigate } from 'gatsby';
import { useBeforeUnload } from 'react-use';
import CheckoutSquare from './CheckoutSquare';
import CheckoutClover from './CheckoutClover';

import { alert, businesses, checkout, tickets } from '../../../state';
import ChargeBreakdown from './ChargeBreakdown';
import OrderDetails from './OrderDetails';
import { handleIOSMsg } from '../../../utils';

// eslint-disable-next-line react/prop-types
const TicketCheckout = ({ changeStep }) => {
  const { t } = useTranslation();
  const business = useSelector(businesses.selectors.selectBusiness);
  const formData = useSelector(tickets.selectors.selectFormData);
  const ticketConfig = useSelector(tickets.selectors.selectTicketConfig);
  const { name, partner } = business;
  const isSquareCheckout = partner === 'Square' || partner === 'BHN';
  const isCloverCheckout = partner === 'Clover';
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!ticketConfig.businessI || business.id) {
      return;
    }
    dispatch(businesses.actions.fetchMerchant(formData.ticketConfig));
  }, [ticketConfig]);

  useBeforeUnload(
    'Enable',
    'If you leave this page your ticket will be released.'
  );

  const padTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };
  const ticketCheckout = (payload) =>
    dispatch(checkout.actions.ticketCheckout(payload));
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const setFormData = (payload) =>
    dispatch(tickets.actions.setFormData(payload));
  const onSubmit = async (result) => {
    const response = await ticketCheckout({
      ...formData,
      nonce: result.token,
      timezone: new Date().getTimezoneOffset(),
    });
    if (response.error) {
      handleIOSMsg('payment_failed');
      openAlert({
        message:
          response.payload?.message ||
          response.error?.message ||
          t('weCannotProcessPaymentsPleaseTryLater'),
        severity: 'error',
      });
    } else if (response.payload) {
      setFormData({ orderNumber: response.payload?.orderNumber });
      changeStep(3);
    }
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setTimeout(() => {
        navigate(`/concert-series/${business.id}`);
      }, 2000);
      return (
        <Typography>
          Your order has expired and you will be redirected to the Events List
          page!
        </Typography>
      );
    }
    const formattedMinutes = padTime(minutes);
    const formattedSeconds = padTime(seconds);
    return (
      <Typography>
        <Trans
          i18nKey="ticket.orderTimeout"
          components={{
            b: <b />,
          }}
          values={{ time: `${formattedMinutes}:${formattedSeconds}` }}
        />
      </Typography>
    );
  };

  return (
    <Box pb={4}>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box my={3}>
          <Typography display="block" align="center" variant="h5">
            {name}
          </Typography>
          <Typography display="block" align="center" variant="subtitle1">
            {t('confirmOrderDetail')}
          </Typography>
        </Box>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={6}
      >
        <Grid item xs={12} sm={5}>
          <OrderDetails />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box mb={3}>
            <ChargeBreakdown />
          </Box>
          <Box mb={2}>
            <Typography display="block" align="left" variant="subtitle1">
              <Countdown
                date={new Date(formData?.reservedTo).getTime()}
                renderer={renderer}
              />
            </Typography>
          </Box>
          {isSquareCheckout && <CheckoutSquare changeStep={changeStep} />}
          {isCloverCheckout && (
            <CheckoutClover onSubmit={onSubmit} formData={formData} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TicketCheckout;
